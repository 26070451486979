import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Seo from "components/sections/seo"
import Header from "components/sections/header"
import Footer from "components/sections/footer"
import Template from "./template"
import LOGO from "images/cortica-logo.svg"

const siteUrl = process.env.GATSBY_SITE_URL || "https://corticacare.com"

const TemplateContainer = ({ data: { footer, header, template } }) => {
  // Organization
  const schema = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    logo: LOGO,
    name: "Cortica Care",
    sameAs: [
      "https://twitter.com/CorticaCare",
      "https://www.facebook.com/CorticaCare",
      "https://www.linkedin.com/company/cortica-/",
      "https://www.instagram.com/corticacare/",
    ],
    url: siteUrl,
  }

  return (
    <>
      <Seo {...template.meta} schemaMarkup={schema} slug={template.slug} />
      <Header {...header} />
      <Template {...template} />
      <Footer {...footer} />
    </>
  )
}

TemplateContainer.props = {
  ...Template.props,
  meta: PropTypes.shape(Seo.props),
}

TemplateContainer.propTypes = {
  data: PropTypes.shape({
    footer: PropTypes.shape(Footer.props),
    header: PropTypes.shape(Header.props),
    template: PropTypes.shape(TemplateContainer.props),
  }),
}

export default TemplateContainer

export const query = graphql`
  query WebPage($slug: String) {
    header: contentfulGlobalHeader(name: { eq: "Header" }) {
      announcement {
        json
      }
      menu {
        menus {
          label
          links {
            text
            path
            nestedLinks {
              text
              path
            }
          }
        }
      }
      utilityLinks {
        path
        text
      }
      cta {
        href: path
        text
      }
    }
    footer: contentfulGlobalFooter(name: { eq: "Footer" }) {
      menu {
        menus {
          label
          links {
            path
            text
          }
        }
      }
      scheduleAppointment {
        path
        text
      }
      socialLinks {
        path
        text
      }
      utilityLinks {
        path
        text
      }
    }
    template: contentfulWebPage(slug: { eq: $slug }) {
      slug
      title
      sections {
        __typename
        ... on ContentfulSectionSplitContent {
          __typename
          backgroundColor
          reverse
          image {
            image {
              alt: description
              title
              file {
                url
              }
            }
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
        }
        ... on ContentfulSectionFaq {
          __typename
          action: callToAction {
            accessibilityLabel
            color
            isOutlined
            isSmall
            href: path
            text
          }
          intro {
            eyebrow: subTitle
            action: callToAction {
              accessibilityLabel
              color
              isOutlined
              isSmall
              href: path
              text
            }
            text {
              json
            }
            title {
              text {
                json
              }
            }
          }
          questions {
            title: question
            text: answer {
              json
            }
          }
        }
        ... on ContentfulOrderedList {
          __typename
          title
          type
          headlineOrdered {
            headlineOrdered
          }
          items {
            __typename
            ... on ContentfulStaffMember {
              __typename
              department
              id
              name
              role
              slug
              picture {
                title
                fluid {
                  src
                }
              }
            }
            ... on ContentfulTreatment {
              __typename
              cta
              name
              slug
              style
              description {
                description
              }
              icon {
                title
                file {
                  url
                }
              }
            }
          }
        }
        ... on ContentfulSectionQuote {
          __typename
          name
          quote {
            id
            quote
          }
        }
        ... on ContentfulSectionRichText {
          __typename
          alignment
          details {
            json
          }
          web_page {
            title
          }
        }
        ... on ContentfulSectionListOfActions {
          __typename
          id
          actions {
            buttonText
            id
            style
            action {
              __typename
              ... on ContentfulActionRedirect {
                __typename
                url
              }
            }
          }
        }
        ... on ContentfulSubheader {
          __typename
          backgroundColor
          headline
          description {
            description
          }
        }
        ... on ContentfulSectionCards {
          __typename
          headline
          subheadline
          actions {
            buttonText
            style
            action {
              __typename
              ... on ContentfulActionRedirect {
                __typename
                url
                openInANewTab
              }
            }
          }
          cards {
            action {
              action {
                __typename
                ... on ContentfulActionRedirect {
                  __typename
                  url
                  openInANewTab
                }
              }
            }
            description {
              description
            }
          }
        }
        ... on ContentfulSectionSideBySide {
          __typename
          subtitle
          title
          sections {
            __typename
            ... on ContentfulSectionDynamic {
              __typename
              sections
              slug
              style
              title
            }
            ... on ContentfulParagraphsList {
              __typename
              layout
              title
              paragraphs {
                alignment
                title
                description {
                  description
                }
                icon {
                  title
                  file {
                    url
                  }
                }
              }
              actions {
                buttonText
                style
                action {
                  __typename
                  ... on ContentfulActionRedirect {
                    __typename
                    openInANewTab
                    title
                    url
                  }
                }
              }
            }
          }
        }
        ... on ContentfulSectionList {
          __typename
          listOptions
          numberOfColumns
          style
          title
          icon {
            title
            file {
              url
            }
          }
        }
        ... on ContentfulSectionDynamic {
          __typename
          dynamicSection: sections
          backgroundColor
          style
          title
          additionalSettings {
            enableDirectionsCTA
            testimonialsCount
          }
          additionalText {
            json
          }
        }
        ... on ContentfulSectionHeroVideo {
          __typename
          backgroundColor
          style
          subtitle
          title
          actions {
            buttonText
            style
            buttonImage {
              title
              file {
                url
              }
            }
            action {
              __typename
              ... on ContentfulActionOpenVideo {
                __typename
                id
                videoWrapper {
                  url
                }
              }
              ... on ContentfulActionRedirect {
                __typename
                title
                url
              }
            }
          }
          backgroundImage {
            title
            file {
              url
            }
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          backgroundVideo {
            title
            file {
              url
            }
          }
          description {
            description
          }
          photo {
            file {
              url
            }
          }
          navigator {
            buttonText
            style
            action {
              __typename
              ... on ContentfulActionNavigate {
                __typename
                section {
                  __typename
                  ... on ContentfulSectionText {
                    __typename
                    title
                  }
                }
              }
            }
            buttonImage {
              title
              fluid(maxWidth: 200, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          video {
            title
            url
          }
          web_page {
            slug
            title
          }
        }
        ... on ContentfulSectionText {
          __typename
          headline
          style
          title
          body {
            body
            childMarkdownRemark {
              html
            }
          }
          textSectionActions {
            buttonText
            name
            style
            action {
              __typename
              ... on ContentfulActionRedirect {
                __typename
                url
              }
            }
          }
        }
        ... on ContentfulSectionWrapper {
          __typename
          backgroundStyle
          title
          backgroundImage {
            title
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          wrapperSections: sections {
            __typename
            ... on ContentfulSectionQuote {
              __typename
              name
              quote {
                id
                quote
              }
            }
            ... on ContentfulCallToAction {
              __typename
              buttonText
              id
              name
              style
              action {
                __typename
                ... on ContentfulActionRedirect {
                  __typename
                  id
                  openInANewTab
                  url
                }
              }
              buttonImage {
                title
                file {
                  url
                }
              }
            }
            ... on ContentfulSectionListOfAccordionCards {
              __typename
              id
              style
              cards {
                headline
                id
                subheadline
                content {
                  content
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
            ... on ContentfulSectionQuote {
              __typename
              id
              name
              quote {
                quote
              }
            }
            ... on ContentfulHeadline {
              __typename
              id
              headline
            }
            ... on ContentfulSectionListOfActions {
              __typename
              id
              actions {
                buttonText
                style
                action {
                  __typename
                  ... on ContentfulActionRedirect {
                    __typename
                    openInANewTab
                    url
                  }
                }
              }
            }
            ... on ContentfulSectionWrapper {
              __typename
              backgroundStyle
              title
              wrapperSections: sections {
                __typename
                ... on ContentfulSectionSideBySide {
                  __typename
                  name
                  style
                  sections {
                    __typename
                    ... on ContentfulSectionForm {
                      __typename
                      formTitleColor
                      subject
                      title
                      description {
                        childMarkdownRemark {
                          html
                        }
                      }
                      disclaimer {
                        childMarkdownRemark {
                          html
                        }
                      }
                      inputFields {
                        __typename
                        ... on ContentfulFormInputFieldSelect {
                          __typename
                          id
                          label
                          options
                          required
                          source
                        }
                        ... on ContentfulFormInputFieldText {
                          __typename
                          label
                          maximumCount
                          placeholder
                          required
                          type
                        }
                        ... on ContentfulFormInputFieldSideBySide {
                          __typename
                          label
                          inputFields {
                            __typename
                            ... on ContentfulFormInputFieldText {
                              __typename
                              id
                              label
                              maximumCount
                              placeholder
                              required
                              type
                            }
                          }
                        }
                        ... on ContentfulFormInputFieldHidden {
                          __typename
                          class
                          dataFieldName
                          fieldId
                          name
                          title
                        }
                      }
                      submitAction {
                        buttonText
                        style
                        action {
                          __typename
                          ... on ContentfulActionFormSubmission {
                            __typename
                            action
                            title
                          }
                        }
                      }
                    }
                    ... on ContentfulSectionRichText {
                      __typename
                      alignment
                      details {
                        json
                      }
                    }
                  }
                }
                ... on ContentfulSectionRichText {
                  __typename
                  alignment
                  details {
                    json
                  }
                }
              }
            }
            ... on ContentfulSectionSideBySide {
              __typename
              name
              style
              sections {
                __typename
                ... on ContentfulSectionForm {
                  __typename
                  formTitleColor
                  subject
                  title
                  description {
                    content: description
                  }
                  disclaimer {
                    childMarkdownRemark {
                      html
                    }
                  }
                  inputFields {
                    __typename
                    ... on ContentfulFormInputFieldSelect {
                      __typename
                      id
                      label
                      options
                      required
                      source
                    }
                    ... on ContentfulFormInputFieldText {
                      __typename
                      label
                      maximumCount
                      placeholder
                      required
                      type
                    }
                    ... on ContentfulFormInputFieldSideBySide {
                      __typename
                      label
                      inputFields {
                        __typename
                        ... on ContentfulFormInputFieldText {
                          __typename
                          id
                          label
                          maximumCount
                          placeholder
                          required
                          type
                        }
                      }
                    }
                    ... on ContentfulFormInputFieldHidden {
                      __typename
                      class
                      dataFieldName
                      fieldId
                      name
                      title
                    }
                  }
                  submitAction {
                    buttonText
                    style
                    action {
                      __typename
                      ... on ContentfulActionFormSubmission {
                        __typename
                        action
                        title
                      }
                    }
                  }
                }
                ... on ContentfulSectionRichText {
                  __typename
                  alignment
                  details {
                    json
                  }
                }
              }
            }
            ... on ContentfulSectionRichText {
              __typename
              alignment
              details {
                json
              }
            }
            ... on ContentfulSectionText {
              __typename
              headline
              style
              title
              body {
                body
                childMarkdownRemark {
                  html
                }
              }
            }
            ... on ContentfulVideoWrapper {
              __typename
              title
              url
            }
            ... on ContentfulSectionList {
              __typename
              listOptions
              numberOfColumns
              style
              title
              icon {
                title
                file {
                  url
                }
              }
            }
            ... on ContentfulParagraphsList {
              __typename
              layout
              title
              paragraphs {
                alignment
                title
                description {
                  description
                }
                icon {
                  title
                  file {
                    url
                  }
                }
              }
              actions {
                buttonText
                style
                action {
                  __typename
                  ... on ContentfulActionRedirect {
                    __typename
                    openInANewTab
                    title
                    url
                  }
                }
              }
            }
          }
        }
      }
      meta: seoMetadata {
        canonical
        keywords
        title
        type
        abstract {
          abstract
        }
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
    }
  }
`
